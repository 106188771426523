import React, {Fragment, useEffect, useState} from 'react';
import Autocomplete from "react-google-autocomplete";
import Constants from "../../../common/Constants";
import Utils from "../../../common/Utils";

const propTypes = {};

const SearchBox = (props) => {
    const root = props;
    const [inputPlace, setInputPlace] = useState(null);
    const [showMessage, setShowMessage] = useState(false);

    const handleAddPlace = (place) => {
        setInputPlace(place)
        setShowMessage(false)
    }

    useEffect(() => {
        if (inputPlace) {
            reRender()
        }
    }, [inputPlace])

    const reRender = () => {
        let input = root.inputSearch;
        let resultList = [];
        const place = inputPlace
        if (place) {
            const lat = place.geometry?.location?.lat();
            const long = place.geometry?.location?.lng();
            if (lat && long) {
                const list = [...root.listSite].filter(
                    (item) =>
                        (item.distance =
                            Utils.getDistanceFromLatLonInKm(
                                lat,
                                long,
                                item.ms_latitude,
                                item.ms_longitude,
                            )),
                );
                list.sort((a, b) => a.distance - b.distance);
                input = place.formatted_address;
                resultList = [...list.slice(0, 10)];
                const result = {
                    result: resultList,
                    textSearch: input,
                };
                root.setSearchResult({ ...result });
            }
        } else {
            if (window.localStorage.getItem('currentLocationLongitude') && window.localStorage.getItem('currentLocationLatitude')) {
                const lat = window.localStorage.getItem('currentLocationLatitude');
                const long = window.localStorage.getItem('currentLocationLongitude');
    
                if (lat && long) {
                    const list = [...root.listSite].filter(
                        (item) =>
                            (item.distance =
                                Utils.getDistanceFromLatLonInKm(
                                    lat,
                                    long,
                                    item.ms_latitude,
                                    item.ms_longitude,
                                )),
                    );
                    list.sort((a, b) => a.distance - b.distance);
                    resultList = [...list.slice(0, 10)];
                    const result = {
                        result: resultList,
                        textSearch: input,
                    };
                    root.setSearchResult({ ...result });
                }
            }
        }
        
    }
    return (
        <Fragment>
            <div className="box">
                <Autocomplete
                    id="inputSearch"
                    className="input-text"
                    apiKey={process.env.NEXT_PUBLIC_GOOOLE_API_KEY}
                    style={{ width: "100%" }}
                    onChange={(event) => {
                        props.setTextSearch(event.target.value);
                        setInputPlace(null)
                    }}
                    onPlaceSelected={handleAddPlace}
                    options={{
                        types: ["(regions)"],
                        componentRestrictions: { country: "uk" },
                    }}
                    placeholder={
                        props.country === Constants.US
                            ? 'Enter ZIP code, Address,...'
                            : 'Enter Postcode, Address,...'
                    }
                    defaultValue={props.inputSearch}
                />
                <button
                    className="btn-pin"
                    onClick={() => {
                        reRender();
                        if (props.inputSearch && !inputPlace) {
                            setShowMessage(true)
                        } else {
                            setShowMessage(false)
                        }
                    }}>
                    FIND
                </button>
            </div>
            {
                showMessage ? (
                    <p style={{fontSize: '14px', fontWeight: '400', color: '#ff6b00'}}>Please select address from Google Map suggestion in dropdown.</p>
                ) : null
            }

        </Fragment>
    );
};

SearchBox.propTypes = propTypes;
export default SearchBox;
