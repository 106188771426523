import Link from 'next/link';
import React from 'react';
import { PopupButton } from '@typeform/embed-react';

const Button = (props) => {
    const {
        idTypeForm,
        href = '',
        title = '',
        style = {},
        icon,
        classBtn,
        disabled,
    } = props;

    //button for typeform
    if (idTypeForm) {
        return (
            <PopupButton
                id={idTypeForm}
                className={`btn-book-free-session white-hover ${classBtn}`}
                style={style}
                size={90}>
                {icon}
                {title}
            </PopupButton>
        );
    }

    if (props.onClick) {
        return (
            <button
                style={style}
                disabled={disabled}
                className={`btn-book-free-session white-hover ${classBtn}`}
                onClick={() => props.onClick()}>
                {icon}
                {title}
            </button>
        );
    }

    return (
        <Link href={href || '#'} passHref>
            {/* <a style={style} className={["btn-book-free-session white-hover"],classBtn}>
                {title}
            </a> */}
            <a
                style={style}
                className={`btn-book-free-session white-hover ${classBtn}`}>
                {title}
            </a>
        </Link>
    );
};

export default Button;
